<template>
  <v-dialog persistent v-model="isEnableDialog" scrollable width="50%">
    <v-form ref="form">
      <v-card>
        <v-card-title class="headline"
          >{{ isEditId != null ? "Update" : "Add" }} Corporate</v-card-title
        >
        <v-card-text class="form_bg">
          <v-container>
            <v-row no-gutters justify="center">
              <v-col md="6">
                <image-upload
                  @upload="
                    (data) => {
                      corporate.file = data;
                    }
                  "
                  @remove="
                    () => {
                      corporate.image = null;
                    }
                  "
                  :image_path="corporate.image"
                  :height="200"
                ></image-upload>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="corporate.name"
                  outlined
                  background-color="#fff"
                  label="Name*"
                  required
                  :rules="[(v) => !!v || 'Name is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="corporate.description"
                  outlined
                  background-color="#fff"
                  label="Description*"
                  required
                  :rules="[(v) => !!v || 'Description is required']"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="corporate.address"
                  label="Address*"
                  outlined
                  background-color="#fff"
                  required
                  :rules="[(v) => !!v || 'Address is required']"
                  rows="3"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="corporate.email"
                  outlined
                  background-color="#fff"
                  label="Email*"
                  required
                  :rules="[
                    (v) => !!v || 'E-mail is required',
                    (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                  ]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <mobile-field
                  v-model="corporate.phone"
                  label="Phone*"
                ></mobile-field>
              </v-col>
            </v-row>
            <div class="titles">Contact Details</div>
            <v-card
              :style="cardStyle"
              class="pt-3 pl-2 pr-2 mb-3"
              elevation="0"
            >
              <v-row>
                <v-col lg="6" md="6" sm="6">
                  <v-text-field
                    label="Contact Person*"
                    required
                    v-model="corporate.contact_person"
                    outlined
                    background-color="#fff"
                    :rules="[(v) => !!v || 'Name is required']"
                  ></v-text-field>
                </v-col>

                <v-col lg="6" md="6" sm="6">
                  <mobile-field
                    v-model="corporate.contact_number"
                    label="Contact Number*"
                  ></mobile-field>
                </v-col>
              </v-row>
            </v-card>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2 white--text blue-color" @click="close"
            >Close</v-btn
          >

          <v-btn
            class="ma-2 white--text teal-color"
            @click="addOrEditCorporate"
            >{{ isEditId != null ? "Update" : "Save" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import MobileField from "../../components/Fields/MobileField.vue";
export default {
  components: { MobileField },
  props: ["isEnableDialog", "isEditId"],
  data() {
    return {
      corporate: {},
    };
  },
  mounted() {
    if (this.isEditId) {
      this.getCorporateDetails();
    }
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
    getCorporateDetails() {
      this.$http
        .get(`venues/companies/${this.isEditId}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.corporate = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addOrEditCorporate() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      delete this.corporate.image;
      var formData = new FormData();
      for (let key in this.corporate) {
        if (key == "file") {
          formData.append("image", this.corporate[key]);
        } else {
          formData.append(key, this.corporate[key]);
        }
      }
      this.showLoader();
      this.$http({
        method: "post",
        data: formData,
        url: "venues/companies" + (this.isEditId ? `/${this.isEditId}` : ""),
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
        .then((response) => {
          if (response.status == 200) {
            this.hideLoader();
            this.$emit("confirm", true);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>