<template>
  <v-container fluid>
    <v-row>
      <v-col class="ml-2" lg="2" sm="3"> </v-col>
      <v-spacer></v-spacer>
      <v-col lg="4" sm="4" style="text-align: center">
        <v-btn-toggle
          borderless
          class="button_navigation"
          v-model="toggle_mode"
          mandatory
          @change="loadCorporates"
        >
          <v-btn value="1">Active</v-btn>

          <v-btn value="2">InActive</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="text-lg-right" lg="2" sm="4">
        <v-btn
          v-if="checkWritePermission($modules.corporate.dashboard.slug)"
          @click="addCorporateDialoge = true"
          class="mr-6 teal-color"
          dark
          height="45"
        >
          Corporate
          <v-icon right dark>mdi-plus-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <template v-for="(corporate, i) in corporateList">
        <v-col :key="i" lg="4">
          <corporate-card
            v-bind="corporate"
            @edit="populateEditCorporate"
            @delete="deleteCorporate"
          />
        </v-col>
      </template>
    </v-row>
    <h3 class="text-center mt-12" v-if="corporateList.length == 0">
      No {{ toggle_mode == 1 ? "active" : "inactive" }} corporate account
      present
    </h3>
    <v-pagination
      v-if="totalPages"
      v-model="page"
      :length="totalPages"
    ></v-pagination>

    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>

    <company-form
      v-if="addCorporateDialoge"
      :is-enable-dialog="addCorporateDialoge"
      :is-edit-id="corporateId"
      @confirm="
        (addCorporateDialoge = false), (corporateId = null), loadCorporates()
      "
      @close="(addCorporateDialoge = false), (corporateId = null)"
    />
  </v-container>
</template>

<script>
import CorporateCard from "@/components/Corporate/CorporateCard";
import CompanyForm from "./CompanyForm.vue";
export default {
  components: {
    CorporateCard,
    CompanyForm,
  },
  data() {
    return {
      toggle_mode: "1",
      addCorporateDialoge: false,
      corporate: {},
      corporateList: [],
      image: null,
      valid: true,
      editImageHover: false,
      page: 1,
      totalPages: 0,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      corporateId: null,
    };
  },
  mounted() {
    this.loadCorporates();
  },
  computed: {
    categoryList() {
      return this.$store.getters.allVenueCategory.data;
    },
  },
  watch: {
    page() {
      this.loadCorporates();
    },
  },
  methods: {
    loadCorporates() {
      this.showLoader("Loading companies");
      let url = `?per_page=6&status_id=${this.toggle_mode}&page=${this.page}`;
      this.$http
        .get(`venues/companies${url}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.corporateList = response.data.data;
            this.hideLoader();
            this.totalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    populateEditCorporate(id) {
      this.corporateId = id;
      this.addCorporateDialoge = true;
    },
    confirmActions(data) {
      if (data.type == "delete") {
        this.changeCorporateStatus(data.id);
      }
      this.confirmModel.id = null;
    },
    changeCorporateStatus(id) {
      this.$http
        .put("venues/companies/" + id + "/status")
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.loadCorporates();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    deleteCorporate(data) {
      let status = data.status == 1 ? "delete" : "activate";
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${status} this corporate?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "delete",
      };
    },
  },
};
</script>

<style scoped>
</style>
