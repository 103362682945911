<template>
  <v-card color="#3e4c55" class="mt-2 mr-2 ml-2">
    <v-row class="pr-2 pl-2">
      <v-col cols="12" md="5">
        <v-card flat tile class="d-flex">
          <v-img
            :src="
              image != null && image != 'undefined'
                ? s3BucketURL + image
                : require('@/assets/images/default_images/class_default.jpg')
            "
            height="140px"
          ></v-img>
        </v-card>
      </v-col>
      <v-col cols="12" md="7">
        <div class="venue_details">
          <h3 class="grey--text text--lighten-5">
            <span
              class="d-inline-block text-truncate"
              style="max-width: 100%"
              >{{ name }}</span
            >
          </h3>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="grey--text text--lighten-3"
                >Email:&nbsp; {{ email }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="grey--text text--lighten-3"
                >Phone: {{ phone }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="grey--text text--lighten-3"
                >Address: {{ address }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </div>
        <v-row no-gutters class="mb-2">
          <v-col col="12" md="125" sm="12" class="mb-2">
            <v-btn small block color="#008483" dark
              >Order:
              {{ company_sale_count | numberFormatter }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters class="pr-2 pl-2 pb-2">
      <v-col cols="12" md="12" class="detail_box">
        <h4 class="text-center grey--text text--lighten-1 mb-1">Description</h4>
        <v-card
          class="pa-2 overflow-y-auto grey--text text--lighten-2 caption"
          style="min-height: 80px; max-height: 80px"
          color="#2d3b46"
          elevation="0"
          tile
          dark
          pa-0
        >
          {{ description }}
        </v-card>
      </v-col>
    </v-row>
    <v-bottom-navigation>
      <v-spacer></v-spacer>
      <v-btn
        v-if="checkWritePermission($modules.corporate.dashboard.slug)"
        @click="editCorporate"
        text
      >
        <span>Edit</span>
        <v-icon medium>mdi-pencil</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="checkReadPermission($modules.corporate.events.slug)"
        @click="viewCorporate"
        text
      >
        <span>View Orders</span>
        <v-icon medium>mdi-order-bool-ascending-variant</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="checkDeletePermission($modules.corporate.dashboard.slug)"
        @click="deleteCorporate"
        text
      >
        <span>{{ status_id == 1 ? "Delete" : "Reactivate" }}</span>
        <v-icon medium
          >mdi-{{ status_id == 1 ? "delete" : "backup-restore" }}</v-icon
        >
      </v-btn>
      <v-spacer></v-spacer>
    </v-bottom-navigation>
  </v-card>
</template>

<script>
export default {
  props: {
    description: { type: String, default: "" },
    phone: { type: String, default: "" },
    email: { type: String, default: "" },
    con: { type: Object },
    id: { type: Number },
    image: { type: String, default: "" },
    address: { type: String, default: "" },
    name: { type: String, default: "0" },
    orders_count: { type: String, default: "0" },
    status_id: { type: Number, default: 1 },
    company_sale_count: { type: Number, default: 0 },
  },

  methods: {
    editCorporate() {
      this.$emit("edit", this.id);
    },
    deleteCorporate() {
      this.$emit("delete", { id: this.id, status: this.status_id });
    },
    viewCorporate() {
      this.$router.push({
        name: "CorporateEventOrders",
        params: { id: this.id },
      });
    },
  },
};
</script>

<style scoped>
.venue_details .v-list-item__content {
  padding: 6px 8px;
}
.venue_details .v-list-item {
  min-height: 10px;
  padding: 0;
  line-height: 10px;
  font-size: 12px;
}
.venue_details .v-list-item__icon {
  margin: 2px 2px 2px 2px;
}
.detail_box {
  padding-top: 0px;
}
.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  line-height: 1;
}
.v-list-item__title {
  align-self: center;
  font-size: 0.8rem;
}
</style>
